<template>
  <header class="h-16 bg-gray-950 flex items-center justify-between px-2">
    <div>
      <UButton
        icon="i-fa6-solid-bars"
        variant="ghost"
        color="white"
        size="xl"
        @click="openSidebar"
      ></UButton>
    </div>
    <div class="flex items-center gap-2">
      <h1 class="text-white text-lg font-semibold">Code Quest RPG</h1>
      <Version />
    </div>
    <div v-if="user">
      <UAvatar size="md" :alt="user.name" :src="user.picture"> </UAvatar>
    </div>
  </header>
</template>

<script setup lang="ts">
import { DashboardSidebar } from "#components";
import { useAuth0 } from "@auth0/auth0-vue";

const { user } = useAuth0();
const slideover = useSlideover();

const openSidebar = () => {
  slideover.open(DashboardSidebar, {
    onClose: slideover.close,
  });
};
</script>
